<template>
  <div id="editorBox" :class="$style.editor">
    <Editor
      id="editor"
      ref="editor"
      v-model="_value"
      api-key="yrss15k7i4oyu1h4xojur98gkjbj2t64rtp2nxzju8bg87un"
      :init="init"
    />
    <div v-if="stop" :class="$style.stop">
      <a-spin />
      <p>正在外部素材转为内部素材,请稍后...</p>
    </div>
    <div v-if="!loading" :class="$style.loading">
      <a-spin />
    </div>
    <chooseMaterial
      :visible.sync="visible"
      :active="materialActive"
      :allow="['image', 'video']"
      :multiple="true"
      @getChoose="onEditor"
    />
    <chooseLink :visible.sync="linkVisible" :data="link.link" @change="getLink" />
    <bj-modal title="跳转链接" :visible="addVisible" @ok="handleOk" @cancel="handleCancel">
      <Bj-form>
        <Bj-input
          v-model="link.text"
          label-align="left"
          v-bind="layout"
          rules="required"
          placeholder="请输入显示文字"
          label="显示文字"
        />
        <BjValidationItem rules="required" label-align="left" label="跳转链接" v-bind="layout">
          <BjButton class="btn-default" @click="onAddLink()">
            <i class="ri-link left" />
            设置链接
          </BjButton>
          <p v-if="link.link" :class="$style.link">{{ link.link.label }}</p>
          <p class="tip mt-10">请选择点击轮播图片需要跳转的链接。</p>
        </BjValidationItem>
        <Bj-input
          v-model="link.title"
          label-align="left"
          v-bind="layout"
          placeholder="请输入链接标题"
          label="链接标题"
        />
      </Bj-form>
    </bj-modal>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

import chooseLink from '@/components/chooseLink'
import chooseMaterial from '@/components/chooseMaterial'
import { systemService } from '@/service'

const service = new systemService()

export default {
  name: 'BjEditor',
  components: {
    Editor,
    chooseMaterial,
    chooseLink,
  },
  props: {
    value: {
      type: [String, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: '请在这里输入',
    },
  },
  data() {
    return {
      linkVisible: false,
      addVisible: false,
      visible: false,
      materialActive: null,
      loading: false,
      stop: false,
      link: {
        text: null,
        title: null,
        link: null,
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  },
  computed: {
    _value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    init() {
      return {
        min_height: 500,
        autoresize_bottom_margin: 10,
        max_height: 800,
        toolbar_sticky: true,
        toolbar_mode: 'sliding',
        content_style: 'img {max-width:100%;} * {margin: 0; paading: 0}',
        menubar: false,
        selector: '#editor',
        icons: 'christmas',
        icons_url: this.oss + '/manage/js/tinymce/setting/tiny_editor_icons.js?' + Math.random(),
        language: 'zh_CN',
        language_url: this.oss + '/manage/js/tinymce/setting/tiny_editor_zh_CN.js',
        fontsize_formats: '12px 14px 15px 16px 17px 18px 20px 24px 36px',
        elementpath: false,
        placeholder: this.placeholder,
        lineheight_formats: '0 0.5 1 1.5 1.8 2 3 4 5',
        letterspacing: '0px 1px 2px 4px 6px 8px 10px',
        tinymceScriptSrc: this.oss + '/manage/js/tinymce/',
        external_plugins: {
          formatpainter: this.oss + '/manage/js/tinymce/plugins/formatpainter.js',
          indent2em: this.oss + '/manage/js/tinymce/plugins/indent2em.js',
          letterspacing: this.oss + '/manage/js/tinymce/plugins/letterspacing.js',
          importword: this.oss + '/manage/js/tinymce/plugins/word.js',
        },
        statusbar: false,
        plugins:
          'print paste importword imagetools searchreplace formatpainter preview hr searchreplace fullscreen directionality visualblocks visualchars fullscreen image media template code codesample charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textpattern help emoticons autoresize autosave',
        toolbar:
          'undo redo | removeformat formatpainter | fontsizeselect bold italic underline strikethrough forecolor backcolor | \
           alignleft aligncenter alignright alignjustify | \
           indent2em outdent indent lineheight letterspacing | \
           blockquote hr emoticons | imgBtn audioBtn videoBtn | searchreplace importword preview print code | linkBtn |fullscreen',
        importword_handler: (editor, files, next) => {
          var file_name = files[0].name
          if (file_name.substr(file_name.lastIndexOf('.') + 1) == 'docx') {
            this.$emit('input', '')
            editor.notificationManager.open({
              text: '正在转换中...',
              type: 'info',
              timeout: 3000,
            })
            next(files)
            this.stop = true
          } else {
            editor.notificationManager.open({
              text: '目前仅支持docx文件格式，若为doc，请将扩展名改为docx',
              type: 'error',
              timeout: 3000,
            })
          }
          // next(files);
        },
        importword_filter: (result, insert) => {
          // 自定义操作部分
          insert(result) //回插函数
          this.$nextTick(() => {
            this.imgToMaterial()
          })
        },
        paste_postprocess: (plugin, args) => {
          if (args.node.getElementsByTagName('img').length) {
            this.stop = true
            this.$nextTick(async () => {
              const ret = this.$refs.editor.editor.dom.select('img')
              for (let index = 0; index < ret.length; index++) {
                const item = ret[index].src
                try {
                  const { data } = await service.imgToMaterial({
                    content: item,
                  })
                  if (data.code === 21) {
                    // ret[index].setAttrib('src', data.path)
                    this.$refs.editor.editor.dom.setAttrib(this.$refs.editor.editor.dom.select('img'), 'src', data.path)
                    this.$message.error('部分图片自动上传失败，请稍后自行处理')
                  } else if (data.code === 20) {
                    // 不修改
                  } else {
                    // ret[index].src = data.path
                    // ret[index].setAttrib('src', data.path)
                    this.$refs.editor.editor.dom.setAttrib(
                      this.$refs.editor.editor.dom.select('img')[index],
                      'src',
                      data.path
                    )
                  }
                } catch (error) {
                  // this.stop = false
                }
              }
              this.stop = false
            })
            // let imgArr = args.node.getElementsByTagName('img')
            // for (let index = 0; index < imgArr.length; index++) {
            //   try {
            //     const { data } = await service.imgToMaterial({
            //       content: imgArr[index].src,
            //     })
            //     if (data.code === 21) {
            //       imgArr[index].src = data.path
            //       this.$message.error('部分图片自动上传失败，请稍后自行处理')
            //     } else if (data.code === 20) {
            //       // 不修改
            //     } else {
            //       console.log(imgArr[index].src)
            //       imgArr[index].src = data.path
            //       console.log(imgArr[index].src)
            //       // this.$nextTick(() => {
            //       //   const arr = this.$refs.editor.editor.getBody().getElementsByTagName('img')
            //       //   for (let cindex = 0; cindex < arr.length; cindex++) {
            //       //     if (arr[cindex].src === imgArr[index].src) {
            //       //       arr[cindex].src = data.path
            //       //       arr[cindex].setAttribute('src', data.path)
            //       //       // console.log(arr)
            //       //       this.$refs.editor.editor.save()
            //       //       console.log(this._value)
            //       //       setTimeout(() => {
            //       //         this.$refs.editor.editor.save()
            //       //         console.log(this._value)
            //       //       }, 2000)
            //       //       // console.log(arr[cindex].src)
            //       //       // console.log(data.path)
            //       //       // console.log(this._value)
            //       //       // const regex = new RegExp(arr[cindex].src, 'gi')
            //       //       // console.log(JSON.stringify(this.$refs.editor.editor.getContent()))
            //       //       // this._value = JSON.stringify(this.$refs.editor.editor.getContent()).replace(regex, data.path)
            //       //       // alert(JSON.stringify(this._value).search(regex))
            //       //       // console.log(1111)
            //       //       // console.log(this._value)
            //       //     }
            //       //   }
            //       // })
            //     }
            //   } catch (error) {
            //     this.stop = false
            //   }
            // }
            // setTimeout(() => {
            // }, 2000)
          }
          if (args.node.getElementsByTagName('video').length) {
            this.$message.error('不能使用外链视频，请通过素材中心上传')
          }
        },
        setup: editor => {
          editor.on('change', function () {
            editor.save()
          })
          // 注册一个icon
          editor.ui.registry.addIcon(
            'img-btn',
            `<i style="font-size: 18px;vertical-align: -2px;" class="ri-image-line" title="图片" />`
          )
          editor.ui.registry.addIcon(
            'video-btn',
            `<i style="font-size: 18px;vertical-align: -2px;" class="ri-play-circle-line" title="视频" />`
          )
          editor.ui.registry.addIcon(
            'audio-btn',
            `<i style="font-size: 18px;vertical-align: -2px;" class="ri-music-line" title="音频" />`
          )
          editor.ui.registry.addIcon(
            'link-btn',
            `<i style="font-size: 18px;vertical-align: -2px;" class="ri-apps-2-line" title="组件" />`
          )
          // editor.ui.registry.addIcon('audio-btn', `<i title="音频" style="font-size: 18px" class="ri-music-line" />`)
          // editor.ui.registry.addIcon('link-btn', `<i title="链接" style="font-size: 18px" class="ri-links-line" />`)
          // 注册按钮
          editor.ui.registry.addButton('imgBtn', {
            icon: `img-btn`,
            onAction: () => {
              this.visible = true
              this.materialActive = 'image'
            },
          })
          editor.ui.registry.addButton('videoBtn', {
            icon: `video-btn`,
            onAction: () => {
              this.visible = true
              this.materialActive = 'video'
            },
          })
          editor.ui.registry.addButton('linkBtn', {
            icon: `link-btn`,
            onAction: () => {
              this.addVisible = true
              // console.log(this.$refs.editor.editor.selection.getRng())
              // console.log(this.$refs.editor.editor.selection.setContent('<strong>Some contents</strong>'))
            },
          })
          // editor.ui.registry.addButton('audioBtn', {
          //   icon: `audio-btn`,
          //   onAction: () => {
          //     this.visible = true
          //     this.materialActive = 'audio'
          //   },
          // })
          // 链接按钮
          // editor.ui.registry.addButton('linkBtn', {
          //   icon: `link-btn`,
          //   onAction: () => {
          //     this.linkVisible = true
          //   },
          // })
        },
      }
    },
  },
  created() {
    this.show()
  },
  methods: {
    show() {
      if (document.getElementsByClassName('tox').length) {
        this.loading = true
        document.getElementById('editor').style.display = 'none'
      } else {
        setTimeout(() => {
          this.show()
        }, 200)
      }
    },
    getLink(data) {
      this.link.link = data
      // console.log(this.$refs.editor.editor.dom.create('a', { link: JSON.stringify(data) }, '12345'))
      // const text = this.$refs.editor.editor.selection.getContent()
      // console.log(this.$refs.editor.editor.selection.setContent(`<a link="${JSON.stringify(data)}}">${text}</a>`))
      // tinymce.activeEditor.selection.setNode(tinymce.activeEditor.dom.create('img', {src: 'some.gif', title: 'some title'}));
      // console.log(`<a link="${JSON.stringify(data)}}">${text}</a>`)
      // this.$refs.editor.editor.selection.setNode(`<a link="${JSON.stringify(data)}}">${text}</a>`)
      // this.$refs.editor.editor.selection.setNode(
      //   this.$refs.editor.editor.dom.create('a', { href: JSON.stringify(data) }, '12345')
      // )
    },
    onAddLink() {
      this.linkVisible = true
    },
    handleOk() {
      if (!this.link.text) {
        this.$message.error('请输入显示文字')
        return
      }
      if (!this.link.link) {
        this.$message.error('请选择链接')
        return
      }
      var range = this.$refs.editor.editor.selection.getRng()
      var node = this.$refs.editor.editor.getDoc().createElement('A')
      node.href = JSON.stringify(this.link.link)
      var t = document.createTextNode(this.link.text)
      node.appendChild(t)
      node.setAttribute('title', this.link.title)
      node.className = 'custom_link'
      // node.setAttribute('label', 'link')
      node.style.color = '#1470df'
      node.style['text-decoration'] = 'underline'
      range.insertNode(node)
      this.addVisible = false
      this.$refs.editor.editor.save()
      this.$message.success('添加成功')
      this.link = {
        text: null,
        title: null,
        link: null,
      }
    },
    handleCancel() {
      this.addVisible = false
    },
    // 富文本选择
    onEditor(file) {
      file.map(item => {
        if (item.type === 'image') {
          this.$refs.editor.editor.execCommand('mceInsertContent', false, `<img src="${item.path}" />`)
        }
        if (item.type === 'video') {
          this.$refs.editor.editor.execCommand(
            'mceInsertContent',
            false,
            `<video controls src="${item.path}"><不支持/video>`
          )
        }
        if (item.type === 'audio') {
          this.$refs.editor.editor.execCommand(
            'mceInsertContent',
            false,
            `<audio controls><source src="${item.path}" type="audio/mp3">不支持</audio>`
          )
        }
      })
    },
    getDom(_arr) {
      let getImageBlob = (url, cb) => {
        var xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        xhr.responseType = 'blob'
        xhr.onload = function () {
          if (this.status == 200) {
            if (cb) cb(this.response)
          }
        }
        xhr.send()
      }
      let imgArr = _arr
      const doneList = []
      const pushImage = (r, idx, len) => {
        doneList.push(r)
        if (idx >= len - 1) {
          return true
        }
        return false
      }
      return new Promise(resolve => {
        for (let index = 0; index < imgArr.length; index++) {
          let blob = imgArr[index].src
          let reader = new FileReader()
          getImageBlob(blob, function (blob) {
            reader.readAsDataURL(blob)
          })
          reader.onload = async e => {
            if (pushImage(e.target.result, index, imgArr.length)) {
              resolve({ domList: imgArr, resList: doneList })
            }
          }
        }
      })
    },
    // 外部图片或者base64转素材
    async imgToMaterial() {
      const ret = await this.getDom(this.$refs.editor.editor.getBody().getElementsByTagName('img'))
      for (let index = 0; index < ret.resList.length; index++) {
        const item = ret.resList[index]
        try {
          const { data } = await service.imgToMaterial({
            content: item,
          })
          if (data.code === 21) {
            ret.domList[index].src = data.path
            this.$message.error('部分图片自动上传失败，请稍后自行处理')
          } else if (data.code === 20) {
            // 不修改
          } else {
            ret.domList[index].src = data.path
          }
        } catch (error) {
          this.stop = false
        }
      }
      this.stop = false
    },
  },
}
</script>

<style lang="less" module>
.editor {
  position: relative;

  .stop {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    text-align: center;
    padding-top: 220px;
    color: #fff;
    min-height: 500px;
    height: 100%;
  }

  .loading {
    text-align: center;
    height: 454px;
    line-height: 454px;
  }

  :global {
    .tox-tinymce {
      border: none;
    }

    .tox .tox-toolbar,
    .tox .tox-toolbar__overflow,
    .tox .tox-toolbar__primary {
      background: none;
    }

    .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
      position: relative;
      border: none;
    }

    .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type):before {
      content: '';
      position: absolute;
      right: 0;
      top: 10.5px;
      height: 18px;
      width: 1px;
      background-color: #eee;
    }

    .tox .tox-tbtn--enabled {
      background: #ddd;
    }

    .tox .tox-tbtn:hover {
      background: #eee;
    }

    .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
      width: auto;
    }
  }
}

// :global {
//   .tox .tox-dialog--width-lg {
// max-width: 430px !important;
//   }
// }
</style>
